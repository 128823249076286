import React from "react";
import heroBackground from "../assets/hero-background.webp";

interface HeroProps {
  pageName?: string;
}

const Hero: React.FC<HeroProps> = ({ pageName }) => {
  return (
    <section className="relative bg-[#F9F4F2] overflow-hidden">
      <div className="relative flex justify-center items-center h-[40vh] md:h-[45vh] lg:h-[50vh] xl:h-[55vh] 2xl:h-[65vh]">
        <div className="absolute inset-0">
          <img
            src={heroBackground}
            alt="Hero Background"
            className="w-full h-full object-cover opacity-100"
            loading="eager"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
