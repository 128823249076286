import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import ImageUpload from "./components/ImageUpload";
import HomePage from "./pages/Homepage";
import LettersAndNumbers from "./pages/LettersAndNumbers";
import GrazingCart from "./pages/GrazingCart";
import Gallery from "./pages/Gallery";
import Reviews from "./pages/Reviews";
import ContactUs from "./pages/ContactUs";
import { Session } from "@supabase/supabase-js";
import GalleryAdmin from "./pages/GalleryAdmin";
import GalleryManager from "./components/GalleryManager";
import { supabase } from "./supabase";
import QRCodeMenu from "./pages/QRCodeMenu";

const App: React.FC = () => {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    // Get the session when the component mounts
    const getSession = async () => {
      const { data } = await supabase.auth.getSession();
      setSession(data.session);
    };

    getSession();

    // Subscribe to auth state changes
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setSession(session);
      }
    );

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/letters-and-numbers" element={<LettersAndNumbers />} />
        <Route path="/grazing-cart" element={<GrazingCart />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/qrcode-menu" element={<QRCodeMenu />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/gallery-manager"
          element={session ? <GalleryManager /> : <Navigate to="/login" />}
        />
        <Route
          path="/upload"
          element={session ? <ImageUpload /> : <Navigate to="/login" />}
        />
        <Route
          path="/gallery-admin"
          element={session ? <GalleryAdmin /> : <Navigate to="/login" />}
        />
      </Routes>
    </Router>
  );
};

export default App;
