import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StaffTopNav from "./StaffTopNav";
import Footer from "./Footer";
import { supabase } from "../supabase";

// URL that indicates the wrong format
const wrongUrlFormat =
  "https://hqvytfuhlaiyxjprhsyd.supabase.co/storage/v1/object/public/speltuploads/images/https";

const GalleryManager: React.FC = () => {
  const [images, setImages] = useState<{ url: string; id: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  // Fetch images from Supabase
  const fetchImages = async () => {
    setLoading(true);

    // Check if user is authenticated
    const { data: session, error: sessionError } =
      await supabase.auth.getSession();
    if (sessionError || !session?.session) {
      navigate("/login"); // Redirect to login if not authenticated
      return;
    }

    const { data, error } = await supabase
      .from("gallery")
      .select("id, imageUrl"); // Fetch the image ID and URL

    if (error) {
      console.error("Error fetching images:", error.message);
    } else {
      const imageUrls = data.map((item: { id: string; imageUrl: string }) => {
        const url = supabase.storage
          .from("speltuploads")
          .getPublicUrl(`images/${item.imageUrl}`).data.publicUrl;
        return { url, id: item.id };
      });

      // Filter out images with the wrong URL format
      const validImages = imageUrls.filter(
        (image) => !image.url.startsWith(wrongUrlFormat)
      );

      // Remove duplicates by ID or URL
      const uniqueImages = validImages.filter(
        (image, index, self) =>
          index === self.findIndex((img) => img.url === image.url)
      );

      setImages(uniqueImages);
    }
    setLoading(false);
  };

  // Fetch images on component mount
  useEffect(() => {
    fetchImages();
    // Remove navigate from the dependency array
  }, []);

  // Handle delete image
  const handleDelete = async (id: string, imageUrl: string) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      setLoading(true);

      try {
        // Extract the filename from the URL
        const fileName = imageUrl.split("/").pop()!;
        console.log("Deleting file:", fileName);

        // Delete from storage
        const { error: storageError } = await supabase.storage
          .from("speltuploads")
          .remove([`images/${fileName}`]);

        if (storageError) {
          console.error(
            "Error deleting image from storage:",
            storageError.message
          );
          setLoading(false);
          return;
        }
        const { error: dbError } = await supabase
          .from("gallery")
          .delete()
          .or(`imageUrl.eq.${fileName},imageUrl.eq.${imageUrl}`);

        if (dbError) {
          console.error("Error deleting image from database:", dbError.message);
        } else {
          console.log("Successfully deleted image with ID:", id);
          await fetchImages();
        }
      } catch (error) {
        console.error("Unexpected error during deletion:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
  };

  // Determine if there are other images besides the .emptyFolderPlaceholder
  const imagesWithoutPlaceholder = images.filter(
    (image) => image.url.split("/").pop() !== ".emptyFolderPlaceholder"
  );
  const showPlaceholder =
    images.length === 1 &&
    images[0].url.split("/").pop() === ".emptyFolderPlaceholder";

  return (
    <>
      <StaffTopNav />
      <div className="flex flex-col min-h-screen">
        <div className="flex-grow flex items-center justify-center bg-gradient-to-r from-green-400 via-teal-500 to-blue-500 py-20">
          <div className="w-full max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8">
            <h2 className="text-4xl font-semibold text-center mb-8">
              Gallery Manager
            </h2>
            {loading ? (
              <p className="text-center text-gray-600">Loading...</p>
            ) : imagesWithoutPlaceholder.length > 0 ? (
              <Slider {...settings}>
                {imagesWithoutPlaceholder.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={image.url}
                      alt={`Gallery Image ${index}`}
                      className="w-full h-auto rounded-lg shadow-lg"
                      loading="lazy"
                    />
                    <button
                      onClick={() => handleDelete(image.id, image.url)}
                      className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded-lg hover:bg-red-600 transition"
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </Slider>
            ) : showPlaceholder ? (
              <p className="text-center text-gray-600 mt-4">
                No images to select.
              </p>
            ) : (
              <p className="text-center text-gray-600 mt-4">
                No images available.
              </p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GalleryManager;
