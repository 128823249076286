import React from "react";
import Hero from "../components/hero";
import Footer from "../components/Footer";
import TopNavigation from "../components/TopNavigation";
import cartMenuImage from "../assets/menu-page-cart.webp";
import LettersAndNumbersImage from "../assets/lettersandnumbers.webp";

const QRCodeMenu: React.FC = () => {
  return (
    <>
      <TopNavigation />
      <Hero />
      <div className="flex flex-col min-h-screen bg-gray-50">
        <main className="flex-grow">
          <header className="bg-[#F9F4F2] text-gray-900 p-4">
            <div className="max-w-7xl mx-auto flex justify-between items-center">
              <h1 className="text-2xl sm:text-3xl md:text-4xl font-extrabold font-typerighter">
                Menu
              </h1>
              <nav>
                <ul className="flex space-x-8 py-2">
                  {["#cart-menu", "#letters-numbers-menu"].map(
                    (section, index) => (
                      <li key={index}>
                        <a
                          href={section}
                          className="text-lg font-speak-pro transition-colors duration-200"
                        >
                          {section
                            .replace("#", "")
                            .replace(/-/g, " ")
                            .replace(/\b\w/g, (char) => char.toUpperCase())}
                        </a>
                      </li>
                    )
                  )}
                </ul>
              </nav>
            </div>
          </header>

          {/* Cart Menu/Price List */}
          <section id="cart-menu" className="py-8 bg-white">
            <div className="max-w-7xl mx-auto px-6">
              <h2 className="text-3xl font-bold mb-8 font-typerighter">
                Cart Menu & Price List
              </h2>
              <p className="text-lg text-gray-700 mb-4 leading-relaxed font-speak-pro">
                All menus are subject to an additional £150 hire fee. This
                includes travel and setup of the cart, staffing, choice of
                wooden boards or palm leaf dishes, wooden forks, and serviettes.
                All foods are served chilled/ambient.
              </p>
              <h3 className="text-2xl font-semibold mb-4 font-typerighter">
                Our Signature Grazing
              </h3>
              <p className="text-lg text-gray-700 mb-4 leading-relaxed font-speak-pro">
                For the perfect board, we recommend choosing 3 meats, 3 cheeses,
                3 fruits/vegetables, and 3 extra nibbles.
              </p>
              <p className="text-lg text-gray-700 mb-8 font-semibold text-xl">
                £14 per person
              </p>

              {/* Signature Grazing Options */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                {[
                  {
                    title: "Meats/Alternatives",
                    items: [
                      "Milano salami",
                      "Peppered salami",
                      "Chorizo",
                      "Prosciutto",
                      "Mortadella",
                      "MEAT FREE falafel",
                      "MEAT FREE sausages",
                    ],
                  },
                  {
                    title: "Cheeses",
                    items: [
                      "Cheddar",
                      "Brie",
                      "Mozzarella",
                      "Gouda",
                      "Hard goats cheese",
                      "Red Leicester",
                      "Manchego",
                    ],
                  },
                  {
                    title: "Fruits/Vegetables",
                    items: [
                      "Grapes",
                      "Olives",
                      "Dried apricots",
                      "Strawberries",
                      "Blueberries",
                    ],
                  },
                  {
                    title: "Extras/Nibbles",
                    items: [
                      "Crackers",
                      "Breadsticks",
                      "Pretzels",
                      "Crostini",
                      "Croutons",
                      "Almonds",
                      "Walnuts",
                      "Onion relish",
                      "Tomato chutney",
                      "Honey",
                    ],
                  },
                ].map((option, index) => (
                  <div
                    key={index}
                    className="p-4 bg-[#F9F4F2] rounded-lg shadow-md"
                  >
                    <h4 className="text-xl font-semibold mb-4">
                      {option.title}
                    </h4>
                    <ul className="list-disc pl-6 text-gray-700 leading-relaxed font-speak-pro">
                      {option.items.map((item, itemIndex) => (
                        <li key={itemIndex}>{item}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>

              {/* Brunch Section */}
              <br />
              <h3 className="text-2xl font-semibold mb-4 font-typerighter">
                Brunch
              </h3>
              <p className="text-lg text-gray-700 mb-4 leading-relaxed font-speak-pro">
                Set menu consisting of the following:
              </p>
              <p className="text-lg text-gray-700 mb-8 font-semibold text-xl">
                £10 per person
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <div className="p-4 bg-[#F9F4F2] rounded-lg shadow-md">
                  <ul className="list-disc pl-6 text-gray-700 leading-relaxed font-speak-pro">
                    <li>Mini Pastries</li>
                    <li>Mini croissants</li>
                    <li>Blueberry muffins</li>
                    <li>Greek yogurt</li>
                    <li>Honey pots</li>
                    <li>Jam pots</li>
                    <li>Granola</li>
                    <li>Swiss cheese</li>
                    <li>English ham</li>
                    <li>Strawberries</li>
                    <li>Blueberries</li>
                    <li>Grapes</li>
                  </ul>
                </div>
              </div>

              {/* Ploughmans Section */}
              <br />
              <h3 className="text-2xl font-semibold mb-4 font-typerighter">
                Ploughmans
              </h3>
              <p className="text-lg text-gray-700 mb-4 leading-relaxed font-speak-pro">
                Set menu consisting of the following:
              </p>
              <p className="text-lg text-gray-700 mb-8 font-semibold text-xl">
                £14 per person
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <div className="p-4 bg-[#F9F4F2] rounded-lg shadow-md">
                  <ul className="list-disc pl-6 text-gray-700 leading-relaxed font-speak-pro">
                    <li>English cheddar</li>
                    <li>Red Leicester</li>
                    <li>Apple slices</li>
                    <li>Grapes</li>
                    <li>Celery sticks</li>
                    <li>English ham</li>
                    <li>Sausage rolls</li>
                    <li>Mini pork pies</li>
                    <li>Cornichons</li>
                    <li>Pickled onions</li>
                    <li>Chutney pots</li>
                    <li>Baguette slices and butter</li>
                  </ul>
                </div>
              </div>

              {/* CHAATcuterie Section */}
              <br />
              <h3 className="text-2xl font-semibold mb-4 font-typerighter">
                CHAATcuterie
              </h3>
              <p className="text-lg text-gray-700 mb-4 leading-relaxed font-speak-pro">
                Choose any 12.
              </p>
              <p className="text-lg text-gray-700 mb-8 font-semibold text-xl">
                £18 per person
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {[
                  {
                    items: [
                      "Chakri/sev",
                      "Naan bites",
                      "Green mango chutney",
                      "Vegetable samosas",
                      "Vegetable pakoras",
                      "Spiced chickpeas",
                      "Pomegranate seeds",
                      "Mini baked potatoes",
                      "Onion salad",
                      "Yogurt dip",
                      "Fried paneer",
                      "Spicy chicken",
                      "Mini popadoms",
                      "Maggi hot and sweet ketchup",
                      "Pani puri shells",
                    ],
                  },
                ].map((option, index) => (
                  <div
                    key={index}
                    className="p-4 bg-[#F9F4F2] rounded-lg shadow-md"
                  >
                    <ul className="list-disc pl-6 text-gray-700 leading-relaxed font-speak-pro">
                      {option.items.map((item, itemIndex) => (
                        <li key={itemIndex}>{item}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              <br />
              <h3 className="text-2xl font-semibold mb-4 font-typerighter">
                Bespoke
              </h3>
              <p className="text-lg text-gray-700 mb-4 leading-relaxed font-speak-pro">
                With 12 slots to fill, we’re more than happy to discuss bespoke
                selections to suit your event perfectly. Just ask and we’ll be
                happy to quote.
              </p>
              <h3 className="text-2xl font-semibold mb-4 font-typerighter">
                'Make a meal of it package'
              </h3>
              <p className="text-lg text-gray-700 mb-4 leading-relaxed font-speak-pro">
                Available with our signature grazing, ploughmans or bespoke
                menus. We’ll serve up sharing bowls of salads and breads to
                dining tables, or a separate buffet table before the start of
                the cart service, and pop out some large palm leaf plates. The
                ultimate grazing experience! We’ll also supply extra staffing to
                ensure everyone is fed quickly and provide a ‘front of house’
                service.
              </p>
              <p className="text-lg text-gray-700 mb-8 font-semibold text-xl">
                Add £8 per person, plus £160.
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <div className="p-4 bg-[#F9F4F2] rounded-lg shadow-md">
                  <ul className="list-disc pl-6 text-gray-700 leading-relaxed font-speak-pro">
                    <li>Herb, lemon and feta couscous</li>
                    <li>Roasted vegetable and tomato pesto pasta salad</li>
                    <li>Rocket and tomato salad with a balsamic dressing</li>
                    <li>Rosemary and garlic focaccia</li>
                  </ul>
                </div>
              </div>
            </div>
            <br></br>
            <br></br>
            <div className="flex justify-centrer mb-8 py-8">
              <img
                src={cartMenuImage}
                alt="Cart Menu"
                className="rounded-lg shadow-md block mx-auto"
              />
            </div>
          </section>
          {/* Letters & Numbers Menu */}
          <section id="letters-numbers-menu" className="py-8 bg-white">
            <div className="max-w-7xl mx-auto px-6">
              <h2 className="text-3xl font-bold mb-8 font-typerighter">
                Letters & Numbers Menu
              </h2>
              <p className="text-lg text-gray-700 mb-4 leading-relaxed font-speak-pro">
                Our A-Z and 0-9 boxes fill to feed 4-5 people as a generous
                starter, or light evening reception/party nibbles. We also offer
                a heart shape box and a + character, which each fill to feed 2-3
                people.
              </p>
              <p className="text-lg text-gray-700 mb-4 leading-relaxed font-speak-pro">
                Having an intimate wedding with 10-13 guests?... Perhaps choose
                ‘H+J’.
              </p>
              <p className="text-lg text-gray-700 mb-4 leading-relaxed font-speak-pro">
                Having a larger wedding with 102-124 guests in attendance?... We
                could spell out:
              </p>
              <p className="mb-6 text-center text-2xl font-semibold text-gray-700 font-speak-pro">
                <span className="inline-block">💖 MR + MRS SMITH 💖</span>
                <br />
                <span className="inline-block">💖 WEDDING 💖</span>
                <br />
                <span className="inline-block">26 💖 7 💖 25</span>
              </p>
              <p className="text-lg text-gray-700 mb-4 leading-relaxed font-speak-pro">
                {" "}
                “HAPPY BIRTHDAY KATIE” would feed 72-90. Or “KATIE 21” would
                feed 28-35. . . . you get the picture!
              </p>
              <p className="text-lg text-gray-700 mb-4 leading-relaxed font-speak-pro">
                Each order comes with a length of black cheesecloth table runner
                to sit the boards on to really make them ‘pop’. We also supply
                napkins, palm leaf plates, and wooden disposable cutlery. Full
                assembly/layout instructions are provided, so they can very
                quickly and easily be displayed at your event, at the time
                needed. Instagram ready!
              </p>
              <p className="text-lg text-gray-700 mb-4 leading-relaxed font-speak-pro">
                We offer free collection from our base in Hinckley,
                Leicestershire, or will deliver up to a 30-mile radius for an
                extra £1 per mile. Our ‘keep cool’ packaging will keep your
                platters cool for up to 24 hours, until they are needed,
                providing it’s kept in a cool, dark place and unopened. This
                packaging is compulsory for deliveries, and optional for
                collections. It comes at a cost of £8 per multiple of 4 boxes,
                rounded up.
              </p>
              <p className="text-lg text-gray-700 mb-4 leading-relaxed font-speak-pro">
                Each box is loaded with a wide selection of fine cheeses,
                charcuterie, and other delights. Typically they contain the
                following, subject to availability:
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <div className="p-4 bg-[#F9F4F2] rounded-lg shadow-md">
                  <ul className="list-disc pl-6 text-gray-700 leading-relaxed font-speak-pro">
                    <li>Milano salami</li>
                    <li>Napoli salami</li>
                    <li>Chorizo</li>
                    <li>Red Leicester</li>
                    <li>Provolone</li>
                    <li>Herby cream cheese swirl</li>
                    <li>Falafel</li>
                    <li>Olives</li>
                    <li>Grapes</li>
                    <li>Chutney</li>
                    <li>Walnuts</li>
                    <li>Almonds</li>
                    <li>Dried apricots</li>
                    <li>Cherry tomatoes</li>
                    <li>Edible florals and herby garnishes</li>
                  </ul>
                </div>
              </div>
              <br></br>
              <p className="text-lg text-gray-700 mb-4 leading-relaxed font-speak-pro">
                We also supply plenty of crackers to accompany the boxes. We are
                always happy to cater for dietary requirements. Although we do
                have to say, all our food is prepared in a multi-use prep
                kitchen where all common allergens are handled. Individual graze
                boxes are available for those who need them, or gluten-free
                crackers, etc. Please just ask to discuss your requirements.
              </p>
              <p className="text-lg text-gray-700 mb-8 font-semibold text-xl">
                £70 per A-Z or 0-9 character. £45 per heart or + shape.
              </p>
            </div>
            <br></br>
            <div className="flex justify-centrer mb-8 py-8">
              <img
                src={LettersAndNumbersImage}
                alt="Cart Menu"
                className="rounded-lg shadow-md block mx-auto max-w-full h-auto md:max-w-lg lg:max-w-xl"
                loading="lazy"
              />
            </div>
            <br></br>
          </section>
          <div className="bg-[#F9F4F2] text-gray-900 py-12 px-8 rounded-lg shadow-lg text-center">
            <h2 className="text-4xl font-bold mb-4 font-typerighter">
              Get in touch today!
            </h2>
            <p className="text-xl mb-6 font-speak-pro">
              Get in touch with us today to start planning your custom
              charcuterie display. Whether it’s a wedding, birthday, or any
              other special occasion, we’re here to help you create something
              truly unique.
            </p>
            <a
              href="/contact-us"
              className="inline-block bg-[#F9F8F2] text-gray-900 py-3 px-8 rounded-lg text-lg font-semibold font-typerighter border-2 border-gray-400 hover:bg-gray-200 transition-colors"
            >
              Contact Us Now
            </a>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default QRCodeMenu;
